import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "../../types";
import {
  MutationCreateMangoUserArgs,
  MutationUpdateUserArgs,
  QueryUserListArgs,
  UserType,
  UserTypeResponse,
} from "../../../../graphql/types/types";
import { client as apollo } from "../../../../graphql/apollo";
import { USER_LIST } from "../../../../graphql/requests/query/userList";
import { UserListResponse, UserResponse, UserState } from "./types";
import { USER_ITEM } from "../../../../graphql/requests/query/userItem";
import toaster from "components/UI/Notifications/Notification";
import { UPDATE_USER } from "graphql/requests/mutation/updateUser";

import {
  setGetUserByID,
  setHistoryUser,
  setUserList,
  setUserListMeta,
} from "./index";
import { prepareUserList } from "./helpers";
import { CREATE_MANGO_USER } from "graphql/requests/mutation/createMangoUser";

export const loadUsers = createAsyncThunk<
  Array<UserType>,
  QueryUserListArgs,
  AsyncThunkConfig
>("user/loadList", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const res = await apollo.query<UserListResponse>({
      query: USER_LIST,
      variables: payload,
    });
    if (res.data.userList) {
      const { data, meta } = res.data.userList;

      const userList = prepareUserList(data);

      dispatch(setUserList(userList));
      dispatch(setUserListMeta(meta));

      return userList;
    } else {
      dispatch(setUserList([]));
      return [];
    }
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getUserByID = createAsyncThunk<
  UserState,
  string,
  AsyncThunkConfig
>("user/getUserByID", async (payload, { dispatch }) => {
  try {
    const res = await apollo.query({
      query: USER_ITEM,
      variables: {
        id: payload,
      },
    });
    if (res.data.user) {
      const resData = res.data.user;
      dispatch(setGetUserByID(resData));
      return resData;
    }
  } catch (err) {
    console.error(err);
  }
});

export const getUserHistory = createAsyncThunk<void, string, AsyncThunkConfig>(
  "user/getUserHistory",
  async (payload, { dispatch }) => {
    try {
      const res = await apollo.query({
        query: USER_ITEM,
        variables: {
          id: payload,
        },
      });
      if (res.data.user) {
        const resData = res.data.user;
        dispatch(setHistoryUser(resData));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const fillUsersHistory = createAsyncThunk<
  void,
  undefined,
  AsyncThunkConfig
>("user/historyUserList", async (_, { getState, dispatch }) => {
  try {
    const { claims, replies, vacancies, calls, user } = getState();

    const { claimHistory } = claims;
    const { currentReplyHistory } = replies;
    const { vacancyHistory } = vacancies;
    const {
      replyHotCallList,
      replyActivityCallList,
      claimHotCallList,
      claimActivityCallList,
    } = calls;
    const loadedUserIdList = Object.keys(user.historyUserList);
    const payloadIdList: string[] = [];

    [
      ...claimHistory,
      ...currentReplyHistory,
      ...vacancyHistory,
      ...replyHotCallList,
      ...replyActivityCallList,
      ...claimHotCallList,
      ...claimActivityCallList,
    ].forEach((item) => {
      const userId = item?.userId ? String(item.userId) : "";

      if (
        userId &&
        !loadedUserIdList.includes(userId) &&
        !payloadIdList.includes(userId)
      ) {
        payloadIdList.push(userId);
      }
    });
    if (payloadIdList.length) {
      dispatch(fillUserHistoryList(payloadIdList));
    }
  } catch (err) {
    toaster.error({
      title:
        err?.message ||
        "Ошибка загрузки пользователей истории изменения отклика",
    });
  }
});

export const updateUser = createAsyncThunk<
  UserResponse,
  MutationUpdateUserArgs,
  AsyncThunkConfig
>("user/updateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_USER,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const fillUserHistoryList = createAsyncThunk<
  void,
  string[],
  AsyncThunkConfig
>("user/fillUserHistoryList", (payload, { dispatch }) => {
  const requests = payload
    .map((request) => (request ? dispatch(getUserHistory(request)) : null))
    .filter(Boolean);

  Promise.all(requests).catch((err) => {
    console.error(err);
  });
});

export const createMangoUser = createAsyncThunk<
  UserResponse | null | undefined,
  MutationCreateMangoUserArgs,
  AsyncThunkConfig
>("user/createMangoUser", async (userId, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<UserResponse>({
      mutation: CREATE_MANGO_USER,
      variables: userId,
    });
    toaster.success({
      title: "Учетная запись успешно создана.",
    });
    return res.data;
  } catch (err) {
    toaster.error({
      title: err.message,
    });
    return rejectWithValue(err.message);
  }
});
