import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "services/store/types";
import { client as apollo } from "graphql/apollo";
import { CREATE_HOT_CALL } from "graphql/requests/mutation/createHotCall";
import { HotCallType, HotCallInputType } from "graphql/types/types";
import toaster from "../../../../components/UI/Notifications/Notification";

export const fetchCall = createAsyncThunk<
  HotCallType,
  HotCallInputType,
  AsyncThunkConfig
>("callsMango/fetchCall", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.query<HotCallType>({
      query: CREATE_HOT_CALL,
      variables: { form: payload },
    });
    return res.data;
  } catch (err) {
    toaster.error({
      title: err.message,
    });
    return rejectWithValue(err.message);
  }
});
